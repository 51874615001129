<template>
  <v-sheet class="fluid-window back-img">
    <div class="transparent content-top-padding">
      <h1 class="white--text text-center client-title-div mb-8">
        <i18n path="detail.sales" />
      </h1>
    </div>
    <v-data-table
      dark
      :headers="headers"
      :items="items"
      item-key="idx"
      class="mx-2 main-data-table transparent"
      :footer-props="foot_props"
      :no-data-text="$t('nodata')"
    >
      <template #item.price="{item}">{{item.price|currency}}</template>
      <template #item.sale="{item}">{{item.sale?item.sale:item.sale1}}</template>
      <template #item.ttype="{item}">
        <i18n :path="'ttype.'+item.ttype" />
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
export default {
  name: "Sales",
  data() {
    return {
      items: [],
      headers: [
        {
          text: this.$t("fields.created"),
          value: "created",
        },
        {
          text: this.$t("fields.salename"),
          value: "sale",
        },
        {
          text: this.$t("fields.saletype"),
          value: "ttype",
        },
        {
          text: this.$t("fields.from_date"),
          value: "from_date",
        },
        {
          text: this.$t("fields.to_date"),
          value: "to_date",
        },
        {
          text: this.$t("fields.price"),
          value: "price",
          align: "right",
        },
      ],
      foot_props: {
        itemsPerPageOptions: [14, 20, 50, 100], // -> Add this example
        showFirstLastPage: true,
        firstIcon: "mdi-arrow-collapse-left",
        lastIcon: "mdi-arrow-collapse-right",
        prevIcon: "mdi-less-than",
        nextIcon: "mdi-greater-than",
        itemsPerPageText: this.$t("rowPerPage"),
      },
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$api.sales().then((response) => {
      try {
        this.items = [...response];
      } catch (error) {
        this.items = [];
      }
    });
  },
};
</script>
<style scoped>
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
</style>
